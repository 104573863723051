export function plansDiscount(plans) {
  let plansgeneraldata = [];
  //   var discountmodel = plans.data.filter((x) => x.discount != {});
  let selectedDiscountModel = {};

  //   if (discountmodel.length > 0) {
  //     selectedDiscountModel = discountmodel[0];
  //   }
  plans.data.forEach((plan) => {
    selectedDiscountModel = plan.discount;
    var currentplanselected = {
      annualPrice:
        plan.annualCompareAtPrice == 0 || plan.annualCompareAtPrice == undefined
          ? plan.annualPrice
          : plan.annualCompareAtPrice,
      auctionCountLimit: plan.maxCampaignsCount,
      auctionProductLimit: plan.maxVariantsCount,
      createdDate: '2023-05-07T18:04:44.997778Z',
      description: plan.description,
      featureTitleDesciption: plan.featureHeader,
      features: [],
      id: plan.id,
      isActive: plan.isActive,
      isCurrentPlan: plan.isCurrentPlan,
      isDefaultPlan: plan.isDefault,
      isFree:
        (plan.annualPrice === 0 && plan.monthlyPrice === 0) ||
        selectedDiscountModel.percentage === 100
          ? true
          : false,
      isRecommended: plan.isRecommended,
      isUnlimitedCampaigns: plan.isUnlimitedCampaigns,
      isUnlimitedVariants: plan.isUnlimitedVariants,
      monthlyPrice:
        plan.monthlyCompareAtPrice == 0 || plan.monthlyCompareAtPrice == undefined
          ? plan.monthlyPrice
          : plan.monthlyCompareAtPrice,
      name: plan.name,
      order: plan.order,
      permissions: plan.permissions,
      plankey: plan.name,
      top_features: plan.features.map((myfeaturess, index) => {
        return {
          id: '',
          title: myfeaturess,
          parent: null,
          isActive: true,
          order: index,
          description: plan.features[index],
        };
      }),
      trialDays: plan.trialDays,
      trialEligible: true,
      discount: {
        apply_Type: 0,
        code: selectedDiscountModel.code,
        interval_limit: selectedDiscountModel.intervalLimit,
        is_unlimited_interval: false,
        monthlyDiscountedPlanNames: plans.data.map((keys, index) => {
          return keys.name;
        }),
        name: selectedDiscountModel.code,
        percentage: selectedDiscountModel.percentage,
        type: 2,
      },
      period: plan.currentPlanPeriod === 'monthly' ? 0 : 1,
      newAnnualPrice: plan.isCurrentPlan
        ? undefined
        : plan.annualPrice == 0 || plan.annualPrice == undefined
        ? plan.annualCompareAtPrice
        : plan.annualPrice,
      newMonthlyPrice: plan.isCurrentPlan
        ? undefined
        : plan.monthlyPrice == 0 || plan.monthlyPrice == undefined
        ? plan.monthlyCompareAtPrice
        : plan.monthlyPrice,
      plans: {
        annualPrice: plan.annualPrice,
        auctionCountLimit: plan.maxCampaignsCount,
        auctionProductLimit: plan.maxVariantsCount,
        createdDate: '2023-05-07T18:04:44.997778Z',
        description: plan.description,
        featureTitleDesciption: plan.featureHeader,
        features: [],
        id: plan.id,
        isActive: plan.isActive,
        isCurrentPlan: plan.isCurrentPlan,
        isDefaultPlan: plan.isDefault,
        isFree: plan.annualPrice === 0 && plan.monthlyPrice === 0 ? true : false,
        isRecommended: plan.isRecommended,
        isUnlimitedCampaigns: plan.isUnlimitedCampaigns,
        isUnlimitedVariants: plan.isUnlimitedVariants,
        monthlyPrice: plan.monthlyPrice,
        name: plan.name,
        order: plan.order,
        permissions: plan.permissions,
        plankey: plan.name,
        top_features: plan.features.map((myfeaturess, index) => {
          return {
            id: '',
            title: myfeaturess,
            parent: null,
            isActive: true,
            order: index,
            description: plan.features[index],
          };
        }),
        trialDays: plan.trialDays,
        trialEligible: true,
        discount: {
          apply_Type: 0,
          code: 'LLDD',
          interval_limit: 1,
          is_unlimited_interval: false,
          monthlyDiscountedPlanNames: ['Basic', 'BFCM Offer', 'Pro', 'Premium'],
          name: 'GeneralPermume',
          percentage: 50,
          type: 2,
        },
        period: plan.currentPlanPeriod === 'monthly' ? 0 : 1,
        newAnnualPrice: plan.annualCompareAtPrice,
        newMonthlyPrice: plan.monthlyCompareAtPrice,
      },
    };
    plansgeneraldata.push(currentplanselected);
  });

  const getPlans = {
    data: {
      success: true,
      data: {
        plans: plansgeneraldata,
        discount: {
          apply_Type: 0,
          code: selectedDiscountModel.code,
          interval_limit: selectedDiscountModel.intervalLimit,
          is_unlimited_interval: false,
          monthlyDiscountedPlanNames: plans.data.map((keys, index) => {
            return keys.name;
          }),
          name: selectedDiscountModel.code,
          percentage: selectedDiscountModel.percentage,
          type: selectedDiscountModel.period == 'monthly' ? 0 : 1,
        },
      },
    },
  };

  return getPlans;
}

import {
  SET_HELP_PAGE,
  SET_FAQ_LIST,
  SET_LOADING,
  SET_FEED_BACK_GIVEN_PAGE,
} from 'states/constants';

const initialState = {
  helpPages: [],
  loading: true,
  faq: [],
  feedbackGivenPages: [],
};

export const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP_PAGE:
      return {
        ...state,
        loading: false,
        helpPages: [...state.helpPages, action?.payload],
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action?.payload,
      };
    case SET_FAQ_LIST:
      return {
        ...state,
        loading: false,
        faq: action?.payload,
      };
    case SET_FEED_BACK_GIVEN_PAGE:
      return {
        ...state,
        feedbackGivenPages: [...state.feedbackGivenPages, action?.payload],
      };

    default:
      return state;
  }
};

import {
  FETCH_SHOPINFO,
  UPDATE_SHOP_INFO,
  UPDATE_CAN_ADD_ACTION,
  UPDATE_DASHBOARD_REPORT,
  SET_CURRENT_PLAN,
  SET_ACCESSIBILITY_AND_CURRENT_PLAN,
  UPDATE_DASHBOARD_REPORT_LOADING,
  UPDATE_DASHBOARD_REPORT_FAILED,
  SET_NOTIFICATIONS,
} from '../constants';

const initialState = {
  shopInfo: {},
  currentPlan: undefined,
  accessibility: undefined,
  dashboardReport: {},
  notifications: [],
  dashboardReportLoading: true,
  loadingShopInfo: true,
  loadingPermissions: true,
};

export const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHOPINFO:
      return {
        ...state,
        shopInfo: action?.payload,
        loadingShopInfo: false,
      };
    case SET_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: action?.payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action?.payload,
      };
    case UPDATE_SHOP_INFO:
      return {
        ...state,
        shopInfo: { ...state?.shopInfo, ...action?.payload },
      };
    case SET_ACCESSIBILITY_AND_CURRENT_PLAN:
      return {
        ...state,
        ...action?.payload,
        loadingPermissions: false,
      };
    case UPDATE_DASHBOARD_REPORT:
      return {
        ...state,
        dashboardReportLoading: false,
        dashboardReport: action?.payload,
      };
    case UPDATE_DASHBOARD_REPORT_LOADING:
      return {
        ...state,
        dashboardReportLoading: action?.payload,
      };
    case UPDATE_DASHBOARD_REPORT_FAILED:
      return {
        ...state,
        dashboardReportLoading: false,
        dashboardReport: {},
      };
    case UPDATE_CAN_ADD_ACTION:
      return {
        ...state,
        shopInfo: {
          ...state?.shopInfo,
          data: { ...state?.shopInfo.data, canAddCampaign: action?.payload },
        },
      };
    default:
      return state;
  }
};

import {
  CLEAR_DISCOUNT_ERROR,
  FETCH_PLANS,
  SET_DISCOUNT_ERROR,
  SET_DISCOUNT_LOADING,
  SET_PALNS_AND_DISCOUNT,
  SET_PALNS_WITHOUT_DISCOUNT,
  UPGRADE_MODAL_CLOSE,
  UPGRADE_MODAL_OPEN,
} from '../constants';
import { getPlans, getPlansWithDiscount } from 'api/service';
import { showToast } from 'states/actions/toast';
import i18next from 'i18next';

export const getPlansAction = (dispatch) => async () => {
  getPlans((success, data) => {
    if (success) {
      dispatch({
        type: FETCH_PLANS,
        payload: data.data || [],
      });
    } else {
      dispatch({ type: FETCH_PLANS, payload: [] });
    }
  });
};
export const getPlansWithoutDiscountAction = (dispatch) => async () => {
  setDiscountLoading(dispatch)(true);
  getPlans((success, data) => {
    if (success) {
      dispatch({
        type: SET_PALNS_WITHOUT_DISCOUNT,
        payload: data.data || [],
      });
    } else {
      dispatch({ type: SET_PALNS_WITHOUT_DISCOUNT, payload: [] });
    }
  });
};

export const getPlansWithDiscountAction = (dispatch) => async (discountCode, afterSuccess) => {
  setDiscountLoading(dispatch)(true);
  getPlansWithDiscount({ discountCode }, (success, data) => {
    if (success) {
      afterSuccess && afterSuccess();
      dispatch({
        type: SET_PALNS_AND_DISCOUNT,
        payload: data.data,
      });
    } else {
      if (data.message_key) {
        dispatch({
          type: SET_DISCOUNT_ERROR,
          payload: i18next.t(data.message_key),
        });
      } else {
        showToast(dispatch)(i18next.t('msg_st_went_wrong'), {
          error: true,
        });
        setDiscountLoading(dispatch)(false);
      }
    }
  });
};
export const setDiscountLoading = (dispatch) => async (loading) => {
  dispatch({
    type: SET_DISCOUNT_LOADING,
    payload: loading,
  });
};
export const clearDiscountError = (dispatch) => async () => {
  dispatch({
    type: CLEAR_DISCOUNT_ERROR,
  });
};
export const upgardeModalOpen = (dispatch) => async (permissionKey, gaEventTrackingInfo) => {
  dispatch({
    type: UPGRADE_MODAL_OPEN,
    payload: { permissionKey, gaEventTrackingInfo },
  });
};
export const upgardeModalClose = (dispatch) => async () => {
  dispatch({
    type: UPGRADE_MODAL_CLOSE,
  });
};

import { useSelector } from 'react-redux';

export const useCurrentPlan = () => {
  const { currentPlan } = useSelector((state) => state.shop);
  // const currentPlan = plans?.find(
  //   (plan) => plan?.id === shopInfo?.data?.currentPlan?.currentPlanId
  // ); //for black Friday
  // const currentPlan = plans[plans.length - 1]; //for black Friday
  return currentPlan
    ? {
        ...currentPlan.plan,
        currentPlanDate: currentPlan.currentPlanDate,
        period: currentPlan.currentPlanPeriod,
      }
    : undefined;
};

export function planUsageAdaptor(planUsageData) {
  const getPermissionsFake = {
    data: {
      currentPlan: {
        currentPlanId: planUsageData?.data?.currentPlan?.id,
        currentPlanPeriod: planUsageData?.data?.currentPlanPeriod === 'monthly' ? 0 : 1,
        //TODO: fix it for downgrade
        currentPlanDate: planUsageData?.data?.currentPlanStartedAt,
        CurrentPlanUser: '',
        plan: {
          id: planUsageData?.data?.currentPlan?.id,
          name: planUsageData?.data?.currentPlan?.name,
          plankey: planUsageData?.data?.currentPlan?.name,
          featureTitleDesciption: planUsageData?.data?.currentPlan?.featureHeader,
          monthlyPrice: planUsageData?.data?.currentPlan?.monthlyPrice,
          annualPrice: planUsageData?.data?.currentPlan?.annualPrice,
          isActive: true,
          isDefaultPlan: false,
          createdDate: '2022-11-29T03:39:08.436Z',
          description: planUsageData?.data?.currentPlan?.description,
          auctionCountLimit: planUsageData?.data?.currentPlan?.maxCampaignsCount,
          auctionProductLimit: planUsageData?.data?.currentPlan?.maxVariantsCount,
          trialDays: planUsageData?.data?.currentPlan?.trialDays,
          features: [],
          top_features: [],
          isRecommended: false,
          order: 5,
          isUnlimitedCampaigns: planUsageData?.data?.currentPlan?.isUnlimitedCampaigns,
          isUnlimitedVariants: planUsageData?.data?.currentPlan?.isUnlimitedVariants,
          isFree: false,
          permissions: [],
          period: planUsageData?.data?.currentPlanPeriod === 'monthly' ? 0 : 1,
        },
      },
      trialEligible: true,
      permissions: {
        can_set_schedule:
          planUsageData?.data?.currentPlan?.permissions?.includes('campaign_start_schedule'),
        can_set_exclude_product: planUsageData?.data?.currentPlan?.permissions?.includes(
          'campaign_exclude_products'
        ),
        can_set_discount_type: planUsageData?.data?.currentPlan?.permissions?.includes(
          'campaign_general_change_mode'
        ),
        can_set_onFly_campaign:
          planUsageData?.data?.currentPlan?.permissions?.includes('campaign_auto_update'),
        can_prevent_shopify_discounts: planUsageData?.data?.currentPlan?.permissions?.includes(
          'prevent_shopify_discount'
        ),
        can_set_quantity_discounts: planUsageData?.data?.currentPlan?.permissions?.includes(
          'campaign_quantity_and_volume_discount'
        ),
        can_set_Tire_discounts: planUsageData?.data?.currentPlan?.permissions?.includes(
          'campaign_quantity_and_volume_discount_add_tier'
        ),
        can_set_tier_order_discounts: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_tier_order_discounts'
        ),
        can_set_order_discounts: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_order_discounts'
        ),
        can_set_product_countdown: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_product_countdown'
        ),
        can_set_product_countdown_settings: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_product_countdown_settings'
        ),
        can_set_discount_code_option: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_discount_code_option'
        ),
        can_set_maximum_discount_tier: planUsageData?.data?.currentPlan?.permissions?.includes(
          'can_set_maximum_discount_tier'
        ),
        has_price_rule_scope: true,
        has_discounts_scope: true,
        campaign_quantity_and_volume_discount_theme_extension: planUsageData?.data?.currentPlan?.permissions.includes('campaign_quantity_and_volume_discount_theme_extension')
    
      },
      canAddCampaign:
        (planUsageData?.data?.campaignUsage || 0) <
          planUsageData?.data?.currentPlan?.maxCampaignsCount ||
        planUsageData?.data?.currentPlan?.isUnlimitedVariants,
    },
    success: true,
  };

  return getPermissionsFake;
}

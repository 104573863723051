"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanDtoPermissionsItem = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.PlanDtoPermissionsItem = {
    campaign_include_products: 'campaign_include_products',
    campaign_include_collections: 'campaign_include_collections',
    campaign_include_product_tags: 'campaign_include_product_tags',
    campaign_include_vendors: 'campaign_include_vendors',
    campaign_include_product_types: 'campaign_include_product_types',
    campaign_exclude_products: 'campaign_exclude_products',
    campaign_exclude_collections: 'campaign_exclude_collections',
    campaign_exclude_product_tags: 'campaign_exclude_product_tags',
    campaign_exclude_vendors: 'campaign_exclude_vendors',
    campaign_exclude_product_types: 'campaign_exclude_product_types',
    campaign_auto_update: 'campaign_auto_update',
    campaign_general_change_mode: 'campaign_general_change_mode',
    campaign_quantity_and_volume_discount: 'campaign_quantity_and_volume_discount',
    campaign_quantity_and_volume_discount_add_tier: 'campaign_quantity_and_volume_discount_add_tier',
    can_set_order_discounts: 'can_set_order_discounts',
    can_set_tier_order_discounts: 'can_set_tier_order_discounts',
    campaign_start_schedule: 'campaign_start_schedule',
    campaign_expire_schedule: 'campaign_expire_schedule',
    prevent_shopify_discount: 'prevent_shopify_discount',
    order_theme_extension: 'order_theme_extension',
    campaign_quantity_and_volume_discount_theme_extension: 'campaign_quantity_and_volume_discount_theme_extension',
    can_set_product_countdown: 'can_set_product_countdown',
    can_set_product_countdown_settings: 'can_set_product_countdown_settings',
};

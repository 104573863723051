import { TABLE_ROWS_PER_PAGE } from 'config/constants';
import {
  GET_ESTIMATED_CAMPAIGN,
  DELETE_ESTIMATED_CAMPAIGN,
  SET_PAGINATION,
  RESET_PAGINATION,
  SET_CAMPAIGNS_LOADING,
  SET_CAMPAIGNS,
  SET_FILTERED_CAMPAIGNS,
  SET_FILTERS,
  RESET_FILTERS,
  ADD_CAMPAIGN,
  CLEAR_CAMPAIGN,
  DELETE_ALL_ESTIMATED_PRODUCTS,
  SET_FILTER,
} from '../constants';

const initialState = {
  campaigns: undefined,
  filteredCampaigns: undefined,
  loading: true,
  estimaitedCampaign: [],
  pagination: {
    page: 1,
    from: 0,
    to: TABLE_ROWS_PER_PAGE,
  },
  filters: { queryValue: null, status: null, type: null, dateStart: null, sort: null },
};

export const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CAMPAIGNS:
      return {
        ...state,
        ...action?.payload,
        // filters: { queryValue: null, status: null },
        loading: false,
      };
    case ADD_CAMPAIGN:
      const newC = state.campaigns ? [action?.payload, ...state.campaigns] : [action?.payload];
      return {
        ...state,
        campaigns: newC,
        filteredCampaigns: newC,
        filters: { queryValue: null, status: null },
        loading: false,
      };
    case CLEAR_CAMPAIGN:
      return {
        campaigns: undefined,
        filteredCampaigns: undefined,
        loading: true,
        estimaitedCampaign: [],
        pagination: {
          page: 1,
          from: 0,
          to: TABLE_ROWS_PER_PAGE,
        },
        filters: { queryValue: null, status: null },
      };
    case SET_FILTERED_CAMPAIGNS:
      return {
        ...state,
        filteredCampaigns: action?.payload,
      };
    case GET_ESTIMATED_CAMPAIGN:
      return {
        ...state,
        estimaitedCampaign: [...state.estimaitedCampaign, action?.payload],
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action?.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action?.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action?.payload },
      };
    case RESET_PAGINATION:
      return {
        ...state,
        pagination: {
          page: 1,
          from: 0,
          to: TABLE_ROWS_PER_PAGE,
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: { queryValue: null, status: null },
      };
    case DELETE_ESTIMATED_CAMPAIGN:
      const newPro = state.estimaitedCampaign.filter((c) => c.campaignId !== action?.payload);
      return {
        ...state,
        estimaitedCampaign: newPro ? newPro : [],
      };
    case DELETE_ALL_ESTIMATED_PRODUCTS:
      return {
        ...state,
        estimaitedCampaign: [],
      };
    default:
      return state;
  }
};

import to from 'await-to-js';
import { generateQueryParameter } from 'utils/generateQueryParameter';
import { api } from '.';

import { getShopInfo as ApiGetShopInfo, getShopStorage } from 'rest/dist/gen/api/shops/shops';
import { getShopInfoAdaptor } from 'adaptors/shop/shop-info';
import { getPlanUsage } from 'rest/dist/gen/api/pricing/pricing';
import { planUsageAdaptor } from 'adaptors/plans/plan-usage.adaptor';
import { getSettingDataAdaptor } from 'adaptors/setting/setting-adaptors';
import {
  cancelCampaign,
  estimateRequest,
  getEstimateProducts as orvalGetEstimateProducts,
  updateCampaign,
  deactivateCampaign,
} from 'rest/dist/gen/api/campaigns/campaigns';
import { resultEstimateAdaptor, sendEstimateAdaptor } from 'adaptors/estimate/estimate';

export const getShopInfo = async (callback) => {
  const [error, response] = await to(ApiGetShopInfo());
  const [erroronboard, responseonboard] = await to(getShopStorage());
  if (response) {
    const data = { data: getShopInfoAdaptor(response.data, responseonboard?.data) };

    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getNotifications = async (filter, callback) => {
  const params = await generateQueryParameter(filter);
  const [error, response] = await to(api.getNotifications(params));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const setNotificationClose = async (data, callback) => {
  const [error, response] = await to(api.setNotificationClose(data));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const setNotificationView = async (data, callback) => {
  const [error, response] = await to(api.setNotificationView(data));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getShopPlanInfo = async (callback) => {
  const [error, response] = await to(api.getShopPlanInfo());
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const getPermission = async (callback) => {
  const [error, response] = await to(getPlanUsage());
  if (response) {
    const data = planUsageAdaptor(response);
    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const createAuction = async (values, callback) => {
  const [error, response] = await to(api.createAuction(values));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const deleteAuctions = async (values, callback) => {
  const [error, response] = await to(api.deleteAuctions(values));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const updateCampaignEndDate = async (values, callback) => {
  const [error, response] = await to(
    updateCampaign(values.auctionId, { endAt: values.endDate, status: 'active' })
  );
  if (values.isActive === false) {
    await to(deactivateCampaign(values.auctionId));
  }
  if (response) {
    const data = { date: response };
    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const archiveAuctions = async (values, callback) => {
  const [error, response] = await to(api.archiveAuctions(values));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const unArchiveAuctions = async (values, callback) => {
  const [error, response] = await to(api.unArchiveAuctions(values));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const getEstimatedProductsRequestStatus = async (filter, callback) => {
  // const params = await generateQueryParameter(filter);
  const [error, response] = await to(orvalGetEstimateProducts(filter));
  if (response) {
    const data = resultEstimateAdaptor(response);
    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const getOption = async (filter, callback) => {
  const params = await generateQueryParameter(filter);
  const [error, response] = await to(api.getOption(params));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getPlans = async (callback) => {
  const [error, response] = await to(api.getPlans());
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getPlansWithDiscount = async (filter, callback) => {
  var filtercode = filter.discountCode;
  const params = await generateQueryParameter(filter);
  const [error, response] = await to(api.getPlansWithDiscount(filtercode));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const getShopSetting = async (callback) => {
  const [error, response] = await to(api.getShopSetting());
  if (response) {
    const data = response.data;
    callback(response.data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const saveShopSetting = async (data, callback) => {
  const [error, response] = await to(api.saveShopSetting(data));
  if (response) {
    const data = response.data;
    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const wasItHelpful = async (data, callback) => {
  const [error, response] = await to(api.wasItHelpful(data));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const sendSupportEmail = async (data, callback) => {
  const [error, response] = await to(api.sendSupportEmail(data));
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getFaqList = async (callback) => {
  const [error, response] = await to(api.getFaqList());
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};

export const campaignForceStop = async (id, callback) => {
  const [error, response] = await to(cancelCampaign(id));
  if (response) {
    const data = response.data;
    callback(true, {});
  }
  if (error) {
    callback(false, error);
  }
};
export const getTopFiveCampaign = async (callback) => {
  const [error, response] = await to(api.getTopFiveCampaign());
  if (response) {
    const data = response.data;
    callback(data.success, data);
  }
  if (error) {
    callback(false, error);
  }
};
export const getEstimateProducts = async (values, callback) => {
  const payload = sendEstimateAdaptor(values);
  const [error, response] = await to(estimateRequest(payload));
  if (response) {
    const data = response;
    callback(true, data);
  }
  if (error) {
    callback(false, error);
  }
};

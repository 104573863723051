export async function generateQueryParameter(params = {}) {
  const items = Object.entries(params)
    .filter((item) => [undefined, null].indexOf(item[1]) === -1)
    .map((item) => {
      const key = item[0];
      const title = item[1];
      return `${key}=${title}`;
    });

  return `${items.length > 0 ? '?' : ''}${items.join('&')}`;
}

"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCampaignsSortBy = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.GetCampaignsSortBy = {
    name: 'name',
    createdAt: 'createdAt',
    startAt: 'startAt',
    endAt: 'endAt',
    estimatedProductsCount: 'estimatedProductsCount',
};

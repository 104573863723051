export function plans(plans) {
  var plansgeneraldata = [];
  plans.data.forEach((plan) => {
    var currentplanselected = {
      annualPrice: plan.annualPrice,
      auctionCountLimit: plan.maxCampaignsCount,
      auctionProductLimit: plan.maxVariantsCount,
      createdDate: '2023-05-07T18:04:44.997778Z',
      description: plan.description,
      featureTitleDesciption: plan.featureHeader,
      features: [],
      id: plan.id,
      isActive: plan.isActive,
      isCurrentPlan: plan.isCurrentPlan,
      isDefaultPlan: plan.isDefault,
      isFree: plan.annualPrice === 0 && plan.monthlyPrice === 0 ? true : false,
      isRecommended: plan.isRecommended,
      isUnlimitedCampaigns: plan.isUnlimitedCampaigns,
      isUnlimitedVariants: plan.isUnlimitedVariants,
      monthlyPrice: plan.monthlyPrice,
      name: plan.name,
      order: plan.order,
      permissions: plan.permissions,
      plankey: plan.name,
      top_features: plan.features.map((myfeaturess, index) => {
        return {
          id: '',
          title: myfeaturess,
          parent: null,
          isActive: true,
          order: index,
          description: plan.features[index],
        };
      }),
      trialDays: plan.trialDays,
      trialEligible: true,

      period: plan.currentPlanPeriod === 'monthly' ? 0 : 1,
      newAnnualPrice: plan.annualCompareAtPrice,
      newMonthlyPrice: plan.monthlyCompareAtPrice,
    };
    plansgeneraldata.push(currentplanselected);
  });

  const getPlans = {
    data: { success: true, data: plansgeneraldata },
  };

  return getPlans;
}

import ReactGA from 'react-ga';

export const gaEventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const gaEventTrackWithCallBack = (category, action, label, callBack) => {
  try {
    gaEventTrack(category, action, label);
  } catch (err) {}
  callBack && callBack();
};

export const gaEventTrackEditCampaign = (key, value) => {
  const auctionTypeLabel = [undefined, 'percentage', 'fixed amount'];
  const includeExcludeSelectionLabel = [
    undefined,
    'Collections',
    'Tags',
    'Vendors',
    'Price range',
    'Products & variants',
  ];
  let label;
  switch (key) {
    case 'auctionType':
      label = auctionTypeLabel[value];
      break;
    case 'auctionTypeProductSelection':
      label = `Applies to ${
        value === 0 ? 'All store radio button' : includeExcludeSelectionLabel[value]
      }`;
      break;
    case 'auctionTypeProductExclude':
      label = `Excludes ${value === 0 ? 'None' : includeExcludeSelectionLabel[value]}`;
      break;

    default:
      break;
  }
  label && gaEventTrack('Campaigns edit', 'Click', label);
};

import { mapProducts, productExclude, productSelection } from 'adaptors/campaign/save.adaptor';

export function sendEstimateAdaptor(campaign) {
  const data = {
    isImmediately: true,
    name: 'string',
    type: 'general',
    productInclusionType: productSelection[campaign.auctionTypeProductSelection],
    productExclusionType: productExclude[campaign.auctionTypeProductExclude],
    generalDiscountType: 'percentage',
    generalDiscountValue: 1,
    generalDiscountPriceChangeMode: 'price_discounted_into_compare',
    generalDiscountHandleCompareAtPrice: true,
    generalDiscountCustomCent: null,
    autoUpdateIncluded: false,
    autoUpdateExcluded: false,
    tiers: [],
    collections: campaign.auctionCollections.map((c) => ({
      collectionId: parseInt(c.collectionId),
      isExcluded: c.isExclude,
      meta: { title: c.title },
    })),
    vendors: campaign.auctionVendors.map((t) => ({
      vendorName: t.vendorId,
      isExcluded: t.isExclude,
    })),
    productTypes: campaign.auctionProductTypes.map((t) => ({
      productTypeName: t.productTypeName,
      isExcluded: t.isExclude,
    })),
    products: mapProducts(campaign.auctionProducts),
    tags: campaign.auctionTags.map((t) => ({ tagName: t.tagName, isExcluded: t.isExclude })),
    priceRanges: campaign.auctionPriceRanges.map((t) => ({
      fromPrice: parseFloat(t.fromPrice),
      toPrice: parseFloat(t.toPrice),
      isExcluded: t.isExclude,
    })),
    isReview: campaign.isReview,
  };

  return data;
}

export function resultEstimateAdaptor(res) {
  const response = {
    status: res.status === 'done' ? 1 : 0,
    success: true,
    variantCount: res?.totalVariants || 0,
    data: res.data.map((p) => ({
      productId: p?.id?.toString(),
      productTitle: p.title,
      productSku: p?.variants[0]?.sku,
      variantCount: p?.variants?.length,
      imageUrl: p?.images[0]?.src,
      handle: p?.handle,
      productLink: '',
    })),
  };
  return response;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.discountCodeExists = exports.getTopFiveCampaigns = exports.orderReport = exports.campaignStatusDetail = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary Get detail of campaigns grouped by status
*/
const campaignStatusDetail = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/dashboard/campaignStatusDetail`, method: 'get'
    });
};
exports.campaignStatusDetail = campaignStatusDetail;
/**
* @summary Get list of revenue report based on given date the default is last 7 days
*/
const orderReport = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/dashboard/orderReport`, method: 'get',
        params
    });
};
exports.orderReport = orderReport;
/**
* @summary Get top five campaigns
*/
const getTopFiveCampaigns = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/dashboard/topFiveCampaigns`, method: 'get'
    });
};
exports.getTopFiveCampaigns = getTopFiveCampaigns;
/**
* @summary Check if discount code exists
*/
const discountCodeExists = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/dashboard/discountCodeExists`, method: 'get',
        params
    });
};
exports.discountCodeExists = discountCodeExists;

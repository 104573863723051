"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsDtoPreventDiscountStatus = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.SettingsDtoPreventDiscountStatus = {
    preventing_shopify_discount: 'preventing_shopify_discount',
    in_activating: 'in_activating',
};

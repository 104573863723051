import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
// import { Frame } from '@shopify/polaris';
// import loader from './load';

// import TopNav from '@/layouts/TopNav';
import { useToast } from 'hooks/useToast';
import { dismissToast } from 'states/actions/toast';
import ToastMessage from '@/shared/global/ToastMessage';
import './MainLayout.scss';
import UpgradeModal from '@/shared/UpgradeModal';
import { useUpgradeModalShow } from 'hooks/useUpgradeModalShow';
// import { useEffect } from 'react';
// import { useShopInfo } from 'hooks/useShopInfo';
// import { getCurrentPlanInfo } from 'states/actions/shop';

const MainLayout = ({ component: Component, ...restProps }) => {
  const dispatch = useDispatch();
  const upgradeModalShow = useUpgradeModalShow();
  // const shopInfo = useShopInfo();
  const toast = useToast();
  const handleDismissToast = useCallback(() => {
    dismissToast(dispatch);
  }, [dispatch]);

  // useEffect(() => {
  //   shopInfo?.data &&
  //     loader().then(() => {
  //       window.intercomSettings = {
  //         api_base: 'https://api-iam.intercom.io',
  //         app_id: 'u5qsddiv',
  //         name: shopInfo?.data?.name, // Full name
  //         user_id: shopInfo?.data?.id, // Full name
  //         email: shopInfo?.data?.email, // Email address
  //         shop_domain: shopInfo?.data?.domain, // Shop domain
  //         // created_at: '<%= current_user.created_at.to_i %>', // Signup date as a Unix timestamp
  //         created_at: new Date(shopInfo?.data?.created_at).toLocaleDateString(), // Signup date as a Unix timestamp
  //         source_app: 'Discounty', // Signup date as a Unix timestamp
  //       };
  //       var w = window;
  //       var ic = w.Intercom;
  //       if (typeof ic === 'function') {
  //         ic('reattach_activator');
  //         ic('update', w.intercomSettings);
  //       } else {
  //         var d = document;
  //         var i = function () {
  //           i.c(arguments);
  //         };
  //         i.q = [];
  //         i.c = function (args) {
  //           i.q.push(args);
  //         };
  //         w.Intercom = i;
  //         var l = function () {
  //           var s = d.createElement('script');
  //           s.type = 'text/javascript';
  //           s.async = true;
  //           s.src = 'https://widget.intercom.io/widget/u5qsddiv';
  //           var x = d.getElementsByTagName('script')[0];
  //           x.parentNode.insertBefore(s, x);
  //         };
  //         if (document.readyState === 'complete') {
  //           l();
  //         } else if (w.attachEvent) {
  //           w.attachEvent('onload', l);
  //         } else {
  //           w.addEventListener('load', l, false);
  //         }
  //       }
  //     });
  // }, [shopInfo?.data]);

  return (
    <>
      <Route children={(routeProps) => <Component {...routeProps} />} {...restProps} />

      {toast?.show && <ToastMessage handleDismiss={handleDismissToast} {...toast} />}
      {upgradeModalShow && <UpgradeModal />}
    </>
  );
};

export default MainLayout;

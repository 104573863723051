import { SET_STORE_COLLECTIONS, SET_STORE_PRODUCTS } from '../constants';

const initialState = {
  collections: { loading: true, list: [] },
  products: { loading: true, list: [] },
};

export const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_COLLECTIONS:
      return {
        ...state,
        collections: { loading: false, list: action?.payload },
      };
    case SET_STORE_PRODUCTS:
      return {
        ...state,
        products: { loading: false, list: action?.payload },
      };
    default:
      return state;
  }
};

"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportTicketDtoType = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.SupportTicketDtoType = {
    GENERAL: 'GENERAL',
    BOOK_DEMO: 'BOOK_DEMO',
    CONTACT_US: 'CONTACT_US',
    NEWSLETTER: 'NEWSLETTER',
    Expert: 'Expert',
};

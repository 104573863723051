export function getSettingDataAdaptor(setting) {
  const settingData = {
    data: {
      data: {
        contactEmail: setting.data.primeContactEmail,
        newCampaignEmail: setting.data.newCampaignNotification,
        scheduledCampaignStartEmail: setting.data.startScheduleCampaignNotification,
        activatedCampaignEmail: setting.data.campaignStartedNotification,
        scheduledCampaignLiveEmail: setting.data.campaignStartedNotification,
        liveCampaignExpireEmail: setting.data.campaignFinishedNotification,
        liveCampaignDisableEmail: setting.data.campaignFinishedNotification,
        liveCampaignExpiredEmail: setting.data.campaignFinishedNotification,
        campaignDisabledEmail: setting.data.campaignFinishedNotification,
        enableCampaignEmail: setting.data.campaignStartedNotification,
        orderInDiscountedProductEmail: setting.data.orderOnDiscountedProductNotification,
        preventShopifyDiscountCode: setting.data.preventDiscount,
        loading: false,
        hasExtendedInProgress:
          setting?.data?.preventDiscountStatus === 'preventing_shopify_discount',
        hasextendedActive: setting?.data?.preventDiscountStatus === 'reverting_shopify_discount',
      },
      success: true,
    },
  };

  return settingData;
}

export function setSettingDataAdaptor(setting) {
  const changedSettingData = {
    primeContactEmail: setting.contactEmail,
    preventDiscount: setting.preventShopifyDiscountCode,
    newCampaignNotification: setting.newCampaignEmail,
    startScheduleCampaignNotification: setting.scheduledCampaignStartEmail,
    startExpirationCampaignNotification: setting.liveCampaignExpireEmail,
    campaignStartedNotification: setting.enableCampaignEmail,
    campaignFinishedNotification: setting.liveCampaignDisableEmail,
    orderOnDiscountedProductNotification: setting.orderInDiscountedProductEmail,
  };
  return changedSettingData;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSettings = exports.getSettings = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary Get settings of a shop
*/
const getSettings = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/settings`, method: 'get'
    });
};
exports.getSettings = getSettings;
/**
* @summary Set settings of a shop
*/
const setSettings = (settingsDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/settings`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: settingsDto
    });
};
exports.setSettings = setSettings;

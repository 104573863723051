"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignDtoGeneralDiscountPriceChangeMode = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.CampaignDtoGeneralDiscountPriceChangeMode = {
    price_discounted_into_compare: 'price_discounted_into_compare',
    only_price_discounted: 'only_price_discounted',
    both_price_and_compare_discounted: 'both_price_and_compare_discounted',
    compare_discounted_into_price: 'compare_discounted_into_price',
};

import { usePlans } from 'hooks/usePlans';
import { useCurrentPlan } from 'hooks/useCurrentPlan';

export const useIsRecommendedPlan = (planId) => {
  const plans = usePlans();
  const currentPlan = useCurrentPlan();
  const targetPlan = plans?.find((plan) => plan?.id === planId);
  const currentplangeneral = plans?.find((plan) => plan?.id === currentPlan.id);
  return targetPlan?.isRecommended && targetPlan?.order > currentplangeneral?.order;
};

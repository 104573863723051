export const productSelection = [
  'all_store',
  'collection',
  'tag',
  'vendor',
  'price_range',
  'specific_product',
  'product_type',
];

export const productExclude = [
  'none',
  'collection',
  'tag',
  'vendor',
  'price_range',
  'specific_product',
  'product_type',
];

const generalDiscounType = ['new_price', 'percentage', 'fix_price'];

const quantityDiscountType = ['percentage', 'fix_price'];

const generalDiscountMode = [
  'price_discounted_into_compare',
  'compare_discounted_into_price',
  'only_price_discounted',
  'both_price_and_compare_discounted',
];

const volumeMinType = ['quantity', 'amount'];

const volumeMinApplyType = ['all_items', 'each_item'];

export function mapProducts(products) {
  const variants = [];
  products.forEach((p) => {
    p.variantIds.forEach((v) => {
      const variant = {
        productId: parseInt(p.productId),
        variantId: parseInt(v),
        isExcluded: p.isExclude,
        meta: {
          title: p.title || p.product?.title,
          handle: p.handle || p.product?.handle,
          images: [{ originalSrc: p.image?.src || p.product?.image }],
        },
      };
      variants.push(variant);
    });
  });
  return variants;
}

const calcDiscountType = (hasBulkDiscount) => {
  if (hasBulkDiscount === true) {
    return 'general';
  } else if (hasBulkDiscount === false) {
    return 'volume';
  } else if (hasBulkDiscount === 0) {
    return 'order';
  }
};

export function saveCampaignAdaptor(campaign) {
  if (campaign.hasBulkDiscount === 0) {
    campaign.auctionTypeProductSelection = 0;
    campaign.auctionTypeProductExclude = 0;
  }

  const data = {
    isImmediately: campaign.isImmediately,
    name: campaign.name,
    type: calcDiscountType(campaign.hasBulkDiscount),
    productInclusionType: productSelection[campaign.auctionTypeProductSelection],
    productExclusionType: productExclude[campaign.auctionTypeProductExclude],
    generalDiscountType: generalDiscounType[campaign.auctionType],
    generalDiscountValue: parseFloat(campaign.newPrice) || null,
    generalDiscountPriceChangeMode: generalDiscountMode[campaign.auctionDiscountType],
    generalDiscountHandleCompareAtPrice: campaign.handleCompareAtPrice,
    generalDiscountCustomCent: parseFloat(campaign.roundDigit) / 100,
    volumeDiscountMinimumType: volumeMinType[campaign.quantityDiscountMinType],
    volumeDiscountMinimumApplyType: volumeMinApplyType[campaign.quantityDiscountMinApplyType],
    volumeDiscountIsOncePerOrder: campaign.isApplyOncePerOrder,
    autoUpdateIncluded: campaign.includeOnFly,
    autoUpdateExcluded: campaign.excludeOnFly,
    isOrderDiscountCombined: campaign.isOrderDiscountCombined,
    hasProductCountdown: campaign.hasProductCountdown,
    activeByDiscountCode: campaign.activeByDiscountCode,
    discountCode: campaign.discountCode,
    discountCodeId: campaign.discountCodeId,
    discountCodeAppliesOncePerCustomer: campaign.discountCodeAppliesOncePerCustomer,
    volumeDiscountType:
      quantityDiscountType[campaign.quantityDiscountRules[0]?.discountType] ||
      generalDiscounType[campaign.auctionType],
    startAt: campaign.startDate,
    endAt: campaign.endDate,
    tiers: campaign.quantityDiscountRules?.map((x) => ({
      discountValue: parseFloat(x.discountValue),
      minimumValue: parseFloat(x.minValue),
      maximumValue: parseFloat(x.maxValue),
    })),
    collections: campaign.auctionCollections.map((c) => ({
      collectionId: parseInt(c.collectionId),
      isExcluded: c.isExclude,
      meta: { title: c.title || c.collection?.title },
    })),
    vendors: campaign.auctionVendors.map((t) => ({
      vendorName: t.vendorId,
      isExcluded: t.isExclude,
    })),
    productTypes: campaign.auctionProductTypes.map((t) => ({
      productTypeName: t.productTypeName,
      isExcluded: t.isExclude,
    })),
    products: mapProducts(campaign.auctionProducts),
    tags: campaign.auctionTags.map((t) => ({ tagName: t.tagName, isExcluded: t.isExclude })),
    priceRanges: campaign.auctionPriceRanges.map((t) => ({
      fromPrice: parseFloat(t.fromPrice),
      toPrice: parseFloat(t.toPrice),
      isExcluded: t.isExclude,
    })),
  };

  return data;
}

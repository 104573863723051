"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendAnonymsSupportMail = exports.sendSupportMail = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary support ticket
*/
const sendSupportMail = (supportTicketDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/supports/sendSupportMail`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: supportTicketDto
    });
};
exports.sendSupportMail = sendSupportMail;
/**
* @summary Contact us
*/
const sendAnonymsSupportMail = (supportTicketDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/supports/sendAnonymsSupportMail`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: supportTicketDto
    });
};
exports.sendAnonymsSupportMail = sendAnonymsSupportMail;

import { useSelector } from 'react-redux';

export const usePlans = (isUpgrating, permissionKey) => {
  const plans = useSelector((state) => state?.plans?.plans);
  // const sortedPlans = plans.sort(function (a, b) {
  //   return a?.monthlyPrice - b?.monthlyPrice;
  // });
  const pl = permissionKey ? plans.filter((p) => p.permissions.includes(permissionKey)) : plans;
  return pl;
};

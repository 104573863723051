import { getPlanUsage } from 'rest/dist/gen/api/pricing/pricing';
import { campaignStatusDetail } from 'rest/dist/gen/api/dashboard/dashboard';
export async function fetchPlanUsgae() {
  const data = await getPlanUsage();
  return data;
}

export async function fetchCampaignSttuses() {
  const data = await campaignStatusDetail();
  return data;
}

export async function getCampaignReportByStatusAdaptor() {
  const planUsageData = await getPlanUsage();
  const campaignStatusDetailData = await campaignStatusDetail();

  const activeData = campaignStatusDetailData.data.find((x) => x.status === 'active');
  const inactiveData = campaignStatusDetailData.data.find((x) => x.status === 'in_active');
  const scheduleData = campaignStatusDetailData.data.find((x) => x.status === 'schedule');
  const expiredData = campaignStatusDetailData.data.find((x) => x.status === 'expired');

  const data = {
    data: {
      active: {
        campaignCount: activeData?.count || 0,
        campaignProductCount: activeData?.estimatedProductsCount || 0,
        campaignVariantCount: activeData?.estimatedVariantsCount || 0,
        campaignRevenue: 0,
        campaignOrderCount: 0,
      },
      schedule: {
        campaignCount: scheduleData?.count || 0,
        campaignProductCount: scheduleData?.estimatedProductsCount || 0,
        campaignVariantCount: scheduleData?.estimatedVariantsCount || 0,
        campaignRevenue: 0,
        campaignOrderCount: 0,
      },
      disabled: {
        campaignCount: inactiveData?.count || 0,
        campaignProductCount: inactiveData?.estimatedProductsCount || 0,
        campaignVariantCount: inactiveData?.estimatedVariantsCount || 0,
        campaignRevenue: 0,
        campaignOrderCount: 0,
      },
      expire: {
        campaignCount: expiredData?.count || 0,
        campaignProductCount: expiredData?.estimatedProductsCount || 0,
        campaignVariantCount: expiredData?.estimatedVariantsCount || 0,
        campaignRevenue: 0,
        campaignOrderCount: 0,
      },
      needUpgrade: {
        campaignCount: 0,
        campaignProductCount: 0,
        campaignVariantCount: 0,
      },
      total: {
        campaignCount: planUsageData?.data?.campaignUsage || 0,
        campaignVariantCount: planUsageData?.data?.variantUsage || 0,
      },
    },
    success: true,
    message: 'ok',
  };

  return data;
}

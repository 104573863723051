export function getproductadaptour(productmodel) {
  var arrayliveproductflat = [];
  productmodel.data.forEach((element) => {
    var productdata = {
      product: {
        title: element.title,
        productId: element.id,
        handle: element.handle,
        sku: element.variants[0]?.sku,
        image: element.images[0]?.src,
        productTitle: element.title,
        productLink: element.handle,
        productSku: element.variants[0]?.sku,
        imageUrl: element.images[0]?.src,
      },
    };
    arrayliveproductflat.push(productdata);
  });
  return {
    data: {
      success: true,
      data: {
        id: '',
        status: 1,
        liveProducts: arrayliveproductflat,
      },
    },
  };
}

"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseCampaignResponseDtoProductExclusionType = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.BaseCampaignResponseDtoProductExclusionType = {
    none: 'none',
    collection: 'collection',
    product_type: 'product_type',
    vendor: 'vendor',
    specific_product: 'specific_product',
    tag: 'tag',
    price_range: 'price_range',
};

export const productSelection = {
 all_store: 0,
 none: 0,
 collection: 1,
 tag: 2,
 vendor: 3,
 price_range: 4,
 specific_product: 5,
 product_type: 6,
};

export const generalDiscounType = {
 new_price: 0,
 percentage: 1,
 fix_price: 2,
};

export const quantityDiscountType = {
 percentage: 0,
 fix_price: 1,
};

export const generalDiscountMode = {
 price_discounted_into_compare: 0,
 only_price_discounted: 2,
 both_price_and_compare_discounted: 3,
 compare_discounted_into_price: 1,
};

export const statusEnum = {
 draft: 0,
 active: 1,
 in_active: 2,
 schedule: 3,
 expired: 4,
 need_upgrade: 5,
 processing: 7,
 in_progress: 6,
 queued: 8,
 action_required: 10,
 order_discount_conflict: 13
};

export const volumeMinType = {
 amount: 1,
 quantity: 0,
};

export const volumeMinApplyType = {
 all_items: 0,
 each_item: 1,
};
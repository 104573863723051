"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpgradePlanDtoPeriod = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.UpgradePlanDtoPeriod = {
    monthly: 'monthly',
    annual: 'annual',
};

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Card, InlineStack, Button, List, Text, BlockStack } from '@shopify/polaris';

import { api } from 'api';
import { useIsFreePlan } from 'hooks/useIsFreePlan';
import { showToast } from 'states/actions/toast';
import { useIsRecommendedPlan } from 'hooks/useIsRecommendedPlan';
import { PRICING_PERIODS } from 'config/constants';

import './PlanCard.scss';
import { generateQueryParameter } from 'utils/generateQueryParameter';
import { useCurrentPlan } from 'hooks/useCurrentPlan';
import { getShopInfoAction } from 'states/actions/shop';
import { useTranslation } from 'react-i18next';
import { useIsGoingToDowngrade } from 'hooks/useIsGoingToDowngrade';
import DowngradeModal from '@/modals/DowngradeModal';
import { usePlanDiscount } from 'hooks/usePlanDiscount';
import { gaEventTrackWithCallBack } from 'utils/gaTrack';

const PlanCard = ({ plan, period, afterUpgrade, isUpgrating, gaEventTrackingInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFreePlan = useIsFreePlan(plan?.id);
  // const prevPlan = usePrevPlan(plan?.id);
  const isRecommended = useIsRecommendedPlan(plan?.id);
  const currentPlan = useCurrentPlan();
  const isGoingToDownGrade = useIsGoingToDowngrade(plan?.id);
  // const [isCancelScheduled, cancellationDate] = useCancelPlanSchedule();
  const planDiscount = usePlanDiscount(period);
  const [openDowngradeModal, setOpenDowngradeModal] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);

  const toggleDowngradeModal = useCallback(() => {
    setOpenDowngradeModal((openDowngradeModal) => !openDowngradeModal);
  }, []);

  const upgradePlan = async (planId, planName) => {
    setSubmitLoad(true);

    // gaEventTrack('Plans', 'Click', `${planName}(${period === 0 ? 'monthly' : 'annual'})`);

    const discountCode =
      currentPlan?.id !== planId ||
      (planDiscount &&
        planDiscount[
          period === 0 ? 'monthlyDiscountedPlanNames' : 'yearlyDiscountedPlanNames'
        ].includes(planName))
        ? planDiscount?.code
        : undefined;
    var selectedperiod = period === 0 ? 'monthly' : 'annually';
    const params = await generateQueryParameter({
      planId,
      selectedperiod,
      discountCode: discountCode ? discountCode : undefined,
    });
    var parammodel = {
      planId,
      selectedperiod,
      discountCode: discountCode ? discountCode : undefined,
    };
    api
      .upgradePlan(parammodel)
      .then((resp) => {
        if (resp?.data.hasRedirectUrl) {
          if (resp?.data.hasRedirectUrl) {
            window.open(resp.data?.redirectUrl, '_top');
          } else {
            // const queryParams = new URLSearchParams(window.location.search);
            // const host = queryParams.get('host');
            getShopInfoAction(dispatch)(null, null, () => {
              showToast(dispatch)(t('msg_select_plan_success'));
              setSubmitLoad(false);
              afterUpgrade && afterUpgrade();
            });
          }
        } else {
          setSubmitLoad(false);
          getShopInfoAction(dispatch)(null, null, () => {
            showToast(dispatch)(t('msg_select_plan_success'));
            setSubmitLoad(false);
            afterUpgrade && afterUpgrade();
          });
        }
      })
      .catch((err) => {
        setSubmitLoad(false);
      });
  };

  const selectPlan = (planId, planName) => {
    // if (isGoingToDownGrade) toggleDowngradeModal();
    // else upgradePlan(planId, planName);
    gaEventTrackWithCallBack(
      gaEventTrackingInfo?.category || 'Plans',
      'Click',
      isUpgrating && gaEventTrackingInfo
        ? `${planName} (${gaEventTrackingInfo.label})`
        : `${planName}(${period === 0 ? 'monthly' : 'annual'})`,
      () => (isGoingToDownGrade ? toggleDowngradeModal() : upgradePlan(planId, planName))
    );
  };
  const selectedPeriodIsYearly = period === PRICING_PERIODS.YEAR;
  var hasDiscount =
    ((planDiscount &&
      ((plan?.newAnnualPrice && selectedPeriodIsYearly) || plan?.newMonthlyPrice)) ||
      selectedPeriodIsYearly) &&
    !isFreePlan;
  // if (planDiscount){
  //   if (planDiscount?.percentage==100){
  //     hasDiscount=true;
  //   }
  // }
  var mainPrice = selectedPeriodIsYearly ? plan?.annualPrice : plan?.monthlyPrice;
  if (planDiscount) {
    if (plan?.newAnnualPrice != undefined && selectedPeriodIsYearly) {
      hasDiscount = true;
      mainPrice = selectedPeriodIsYearly ? plan?.newAnnualPrice : plan?.newMonthlyPrice;
    } else if (plan?.newMonthlyPrice != undefined) {
      hasDiscount = true;
      mainPrice = selectedPeriodIsYearly ? mainPrice : plan?.newMonthlyPrice;
    }
  }

  return (
    <div
      className={clsx(
        'plan-card',
        isRecommended && period === currentPlan?.period && 'recommended-plan'
      )}
    >
      <Card>
        <BlockStack gap="500" align={'center'}>
          <Text variant="heading2xl" as="p" fontWeight="semibold" alignment="center">
            {plan?.name}
          </Text>
          <div className="description">
            <Text variant="bodyLg" as="p">
              {plan?.description}
            </Text>
          </div>
          <div className="price-box">
            {isFreePlan ? (
              <BlockStack spacing="extraTight">
                <Text variant="heading3xl" as="p">
                  {t('free')}
                </Text>
              </BlockStack>
            ) : (
              <>
                {hasDiscount && (
                  <p className="oldPrice">
                    <Text variant="bodyMd" as="span">
                      <del>
                        {selectedPeriodIsYearly
                          ? (plan?.monthlyPrice * 12).toFixed(0)
                          : plan?.monthlyPrice}
                      </del>
                    </Text>
                  </p>
                )}
                <InlineStack spacing="extraTight">
                  <sup style={{ fontSize: '1em' }}>
                    <Text variant="bodyMd" as="span">
                      USD
                    </Text>{' '}
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      $
                    </Text>
                  </sup>
                  <Text variant="heading3xl" as="p">
                    {mainPrice}
                  </Text>
                  <sup style={{ fontSize: '1em' }}>
                    <Text variant="bodyMd" as="span">
                      /{selectedPeriodIsYearly ? t('label_year') : t('label_month')}
                    </Text>
                  </sup>
                </InlineStack>
              </>
            )}
          </div>
          {plan?.id === currentPlan?.id && period === currentPlan?.period ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 36,
              }}
            >
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                <Text variant="bodyMd" as="span">
                  <span className="Current-plan">{t('current_plan')}</span>
                </Text>
              </Text>
            </div>
          ) : (
            <Button
              // primary={isRecommended && period === currentPlan?.period}
              loading={submitLoad}
              // disabled //for black Friday
              onClick={() => selectPlan(plan?.id, plan?.name)}
              size="medium"
              // disabled={isCancelScheduled && isFreePlan}
            >
              {/* {shopInfo?.data?.trialEligible && plan?.trialDays > 0 */}
              {!selectedPeriodIsYearly && !isFreePlan && plan?.trialEligible && plan?.trialDays > 0
                ? t('day_free_trial', { count: plan?.trialDays })
                : t('select_plan')}
            </Button>
          )}
        </BlockStack>
        <div style={{ marginBlock: 30 }}>
          <BlockStack gap="500">
            <Text variant="headingMd" as="h2">
              {/* {isFreePlan
                ? t('features')
                : t('all_features_plus', { name: index === 1  ? t('free') : prevPlan?.name })}
                 */}
              {plan?.featureTitleDesciption}
              {/* {get_index_name(index)} */}
            </Text>
            <div className="list">
              <List type="bullet">
                {plan?.top_features?.map((feature) => (
                  <List.Item key={feature.id}>{feature.description}</List.Item>
                ))}
              </List>
            </div>
          </BlockStack>
        </div>
      </Card>

      <DowngradeModal
        isOpen={openDowngradeModal}
        handleClose={toggleDowngradeModal}
        planId={plan?.id}
        downgradePlan={() => upgradePlan(plan?.id, plan?.name)}
      />
    </div>
  );
};

export default PlanCard;

// import { FETCH_PLANS } from '../constants';

import {
  SET_WIDGET_CART_EMBED_SETTING,
  SET_WIDGET_CART_EMBED_SETTING_DATA,
  SET_WIDGET_CART_EMBED_SETTING_ERROR,
  SET_WIDGET_CART_EMBED_SETTING_LOADING,
  SET_WIDGET_SHOP_INFO,
  SET_WIDGET_SHOP_INFO_ERROR,
  SET_WIDGET_SHOP_INFO_LOADING,
} from 'states/constants';

const initialCartEmbedSetting = {
  isActive: false,
  totalLabel: 'Total',
  savedLabel: 'Saved',
  totalColor: '#8CB540',
  savedColor: '#8CB540',
  align: 'default',
  lineThrough: 'true',
  boarderinSavedColor: '#8CB540',
  borderRadius: 8,
  showBorder: true,
};

const initialState = {
  mode: 'cartEmbedSetting',
  loading: false,
  error: null,
  cartEmbedSetting: {
    data: initialCartEmbedSetting,
    loading: false,
    error: null,
  },
};

export const widgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDGET_SHOP_INFO:
      console.log('31');

      return {
        ...state,
        loading: false,
        error: null,
        cartEmbedSetting: {
          data: action?.payload?.cartEmbedSetting || initialCartEmbedSetting,
          loading: false,
          error: null,
        },
      };

    case SET_WIDGET_SHOP_INFO_LOADING:
      return {
        ...state,
        loading: action?.payload,
      };

    case SET_WIDGET_SHOP_INFO_ERROR:
      return {
        ...state,
        error: action?.payload,
      };

    case SET_WIDGET_CART_EMBED_SETTING:
      return {
        ...state,
        cartEmbedSetting: {
          data: action?.payload,
          loading: false,
          error: null,
        },
      };
    case SET_WIDGET_CART_EMBED_SETTING_LOADING:
      return {
        ...state,
        cartEmbedSetting: {
          ...state.cartEmbedSetting,
          loading: action?.payload,
        },
      };
    case SET_WIDGET_CART_EMBED_SETTING_ERROR:
      return {
        ...state,
        cartEmbedSetting: {
          ...state.cartEmbedSetting,
          error: action?.payload,
        },
      };
    case SET_WIDGET_CART_EMBED_SETTING_DATA:
      return {
        ...state,
        cartEmbedSetting: {
          ...state.cartEmbedSetting,
          data: {
            ...state.cartEmbedSetting.data,
            ...action?.payload,
          },
        },
      };

    default:
      return state;
  }
};

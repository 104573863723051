import { baseSingleCampaignFormAdaptor } from './list.adaptor';

function campaignProductAdaptor(campaign) {
  const data = {
    auctionCollections: campaign.collections.map((c) => ({
      collection: { title: c?.meta?.title },
      collectionId: c?.collectionId?.toString(),
      isExclude: c.isExcluded,
    })),
    auctionProducts: campaign.products.map((p) => ({
      productId: p?.productId?.toString(),
      variantId: p?.variantId?.toString(),
      isExclude: p.isExcluded,
      title: p?.meta?.title?.toString(),
      handle: p?.meta?.handle?.toString(),
      image: {src: p?.meta?.images[0]?.originalSrc },
      product: {
        productId: p?.productId?.toString(),
        title: p?.meta?.title?.toString(),
        handle: p?.meta?.handle?.toString(),
        image: p?.meta?.images[0]?.originalSrc,
      },
    })),
    auctionTags: campaign?.tags?.map((t) => ({
      tagName: t.tagName,
      isExclude: t.isExcluded,
    })),
    auctionVendors: campaign?.vendors?.map((t) => ({
      vendorId: t.vendorName,
      isExclude: t.isExcluded,
    })),
    auctionProductTypes: campaign?.productTypes?.map((t) => ({
      productTypeName: t.productTypeName,
      isExclude: t.isExcluded,
    })),
    auctionPriceRanges: campaign?.priceRanges?.map((t) => ({
      fromPrice: t.fromPrice,
      toPrice: t.toPrice,
      isExclude: t.isExcluded,
    })),
  };

  return data;
}

export function singleCampaignFormAdaptor(campaign) {
  const base = baseSingleCampaignFormAdaptor(campaign);
  const productData = campaignProductAdaptor(campaign);
  const data = { ...base, ...productData };
  return { data, success: true, message: 'ok' };
}

import { SET_PREVIEW_CAMPAIGN, SET_PREVIEW_CAMPAIGN_DATA } from 'states/constants';

const initialState = {
  previewCampaign: null,
  previewCampaignData: null,
};

export const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREVIEW_CAMPAIGN:
      return {
        ...state,
        previewCampaign: action?.payload,
      };
    case SET_PREVIEW_CAMPAIGN_DATA:
      return {
        ...state,
        previewCampaignData: action?.payload,
      };
    default:
      return state;
  }
};

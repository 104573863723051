import i18next from 'i18next';
// import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en/default.json';
import fr from './fr/default.json';
// import { convertLanguageJsonToObject } from './translations';
// const CULTURE = process.env.REACT_APP_CULTURE;

export const translationsJson = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};
// const apiKey = 'VtOvTK4Ba6_wk_xFu-Cpyw';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

const queryParams = new URLSearchParams(window.location?.search);
const locale = queryParams.get('locale');

i18next
  // .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translationsJson,
    lng: locale ? locale : 'en',
    fallbackLng: 'en',

    // ns: ['default'],
    // defaultNS: 'default',

    supportedLngs: ['en', 'fr'],

    // backend: {
    //   loadPath: loadPath,
    // },
  });

import { api } from 'api';
import {
  FETCH_SHOPINFO,
  UPDATE_SHOP_INFO,
  UPDATE_CAN_ADD_ACTION,
  UPDATE_DASHBOARD_REPORT,
  SET_CURRENT_PLAN,
  SET_ACCESSIBILITY_AND_CURRENT_PLAN,
  UPDATE_DASHBOARD_REPORT_FAILED,
  UPDATE_DASHBOARD_REPORT_LOADING,
  SET_NOTIFICATIONS,
} from '../constants';
import { getShopInfo, getShopPlanInfo, getPermission, getNotifications } from 'api/service';

export const getShopInfoAction =
  (dispatch) =>
  async (host = '', isLoginPage = false, afterFetch) => {
    if (isLoginPage) {
      dispatch({
        type: FETCH_SHOPINFO,
        payload: {},
      });
      return;
    }
    getShopInfo((success, data) => {
      if (success) {
        dispatch({
          type: FETCH_SHOPINFO,
          payload: { host, ...data },
        });
        getPermissionAction(dispatch)(afterFetch);
      } else {
        return dispatch({ type: FETCH_SHOPINFO, payload: { host } });
      }
    });
    getNotificationsAction(dispatch)();
  };
export const getNotificationsAction = (dispatch) => async (filter) => {
  getNotifications(filter, (success, data) => {
    if (success) {
      dispatch({
        type: SET_NOTIFICATIONS,
        payload: data.data,
      });
    }
  });
};
export const getDasboardReport = (dispatch) => async (disablePageLoading) => {
  // updateDashboardReportLoading(dispatch)(true);
  api.getCampaignReportByStatus().then((res) => {
    if (res?.data?.success) {
      dispatch({
        type: UPDATE_DASHBOARD_REPORT,
        payload: res?.data?.data,
      });
    } else {
      dispatch({
        type: UPDATE_DASHBOARD_REPORT_FAILED,
      });
    }
    disablePageLoading && disablePageLoading();
  });
};
export const getCurrentPlanInfo = (dispatch) => async (afterFetch) => {
  getShopPlanInfo((success, data) => {
    if (success) {
      dispatch({
        type: SET_CURRENT_PLAN,
        payload: data?.data,
      });
      afterFetch && afterFetch();
    }
  });
};
export const getPermissionAction = (dispatch) => async (afterFetch) => {
  getPermission((success, data) => {
    if (success) {
      dispatch({
        type: SET_ACCESSIBILITY_AND_CURRENT_PLAN,
        payload: {
          accessibility: {
            canAddCampaign: data?.data.canAddCampaign,
            trialEligible: data?.data.trialEligible,
            permissions: data?.data.permissions,
          },
          currentPlan: data?.data.currentPlan,
        },
      });
      afterFetch && afterFetch();
    }
  });
};

export const updateShopInfo = (dispatch) => (data) => {
  dispatch({ type: UPDATE_SHOP_INFO, payload: { ...data } });
};
export const updateCanAddAction = (dispatch) => (data) => {
  dispatch({ type: UPDATE_CAN_ADD_ACTION, payload: data });
};
export const updateDashboardReportLoading = (dispatch) => (loading) => {
  dispatch({ type: UPDATE_DASHBOARD_REPORT_LOADING, payload: loading });
};

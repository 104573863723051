import {
  productSelection,
  generalDiscounType,
  quantityDiscountType,
  generalDiscountMode,
  statusEnum,
  volumeMinType,
  volumeMinApplyType,
} from './enum';

function campaignType(campaign) {
  if (campaign.type === 'general') {
    return 0;
  } else if (campaign.type === 'order') {
    return 5;
  } else if (
    campaign?.volumeDiscountMinimumType === 'quantity' &&
    campaign.volumeDiscountMinimumApplyType === 'all_items'
  ) {
    return 1;
  } else if (
    campaign?.volumeDiscountMinimumType === 'quantity' &&
    campaign.volumeDiscountMinimumApplyType === 'each_item'
  ) {
    return 2;
  } else if (
    campaign?.volumeDiscountMinimumType === 'amount' &&
    campaign.volumeDiscountMinimumApplyType === 'all_items'
  ) {
    return 3;
  } else if (
    campaign?.volumeDiscountMinimumType === 'amount' &&
    campaign.volumeDiscountMinimumApplyType === 'each_item'
  ) {
    return 4;
  }
}

function getStatus(campaign) {
  if (
    campaign.status === 'in_progress' &&
    campaign.inProgressStatus === 'preventing_shopify_discount'
  ) {
    return 11;
  } else if (
    campaign.status === 'in_progress' &&
    campaign.inProgressStatus === 'reverting_shopify_discount'
  ) {
    return 12;
  }

  return statusEnum[campaign.status];
}

export function baseSingleCampaignFormAdaptor(
  /** @type {import('rest/dist/gen/models/campaignDto').CampaignDto} */ campaign
) {
  const data = {
    id: campaign.id,
    publishStatus: 1,
    name: campaign.name,
    startDate: campaign.status === 'active' ? campaign.updatedAt : campaign.startAt,
    createdDate: campaign.createdAt,
    updatedDate: campaign.updatedAt,
    endDate: campaign.endAt,
    newPrice: campaign.generalDiscountValue,
    discountPercentage: campaign.generalDiscountValue,
    noDiscount: true,
    roundDigit:
      campaign.generalDiscountCustomCent !== null && campaign.generalDiscountCustomCent >= 0
        ? campaign.generalDiscountCustomCent * 100
        : null,
    isDeleted: false,
    isActive: campaign.status !== 'in_active',
    isLive: campaign.status === 'active',
    isImmediately: campaign.isImmediately,
    isPendingOperate: campaign.status !== 'need_upgrade',
    hasTimeTinStore: true,
    discountIconInProduct: 'string',
    discountColor: 'string',
    colorAfterDiscount: 'string',
    quantityDiscountRules:
      campaign?.tiers?.map((x) => ({
        discountValue: x.discountValue,
        minValue: x.minimumValue,
        maxValue: x.maximumValue,
        discountType: quantityDiscountType[campaign?.volumeDiscountType],
        minType: volumeMinType[campaign?.volumeDiscountMinimumType],
      })) ?? [],
    totalSale: campaign.totalRevenue,
    auctionTypeProductSelection: productSelection[campaign.productInclusionType],
    auctionTypeProductExclude: productSelection[campaign.productExclusionType],
    auctionType: generalDiscounType[campaign.generalDiscountType],
    auctionDiscountType: generalDiscountMode[campaign.generalDiscountPriceChangeMode],
    status: getStatus(campaign),
    estimatedProductsCount: campaign.estimatedProductsCount,
    estimatedVariantsCount: campaign.estimatedVariantsCount,
    updatedVariantCount: campaign?.progress?.done || 0,
    inProgressPercentage: 0,
    //TODO
    queue_progress_type: ['activating', 'preventing_shopify_discount'].includes(
      campaign.inProgressStatus
    )
      ? 0
      : 1,
    needUpgradeDueToDowngrading: campaign.needUpgradeDueToDowngrading,
    lockEditing: campaign.isLocked,
    //TODO
    includeOnFly: campaign.autoUpdateIncluded,
    excludeOnFly: campaign.autoUpdateExcluded,
    handleCompareAtPrice: campaign.generalDiscountHandleCompareAtPrice,
    // canForceStop: campaign.cancelable && campaign.status === 'in_progress',
    canForceStop: false,
    isArchive: false,
    hasBulkDiscount: campaign.type === 'order' ? 0 : campaign.type === 'general',
    hasQuantityDiscount: campaign.type === 'volume',
    isActionRequired: false,
    //TODO
    isApplyOncePerOrder: campaign.volumeDiscountIsOncePerOrder,
    quantityDiscountMinType: volumeMinType[campaign?.volumeDiscountMinimumType],
    quantityDiscountMinApplyType: volumeMinApplyType[campaign.volumeDiscountMinimumApplyType],
    orderCounts: campaign.orderCount,
    type: campaignType(campaign),
    processingProgress: campaign.processingProgress,
    isOrderDiscountCombined: campaign.isOrderDiscountCombined,
    hasProductCountdown: campaign.hasProductCountdown,
    activeByDiscountCode: campaign.activeByDiscountCode,
    discountCode: campaign.discountCode,
    discountCodeId: campaign.discountCodeId,
    discountCodeAppliesOncePerCustomer: campaign.discountCodeAppliesOncePerCustomer,
  };
  return data;
}

export function getCampaignsAdaptors(campaigns) {
  const data = {
    data: campaigns.map((cmp) => baseSingleCampaignFormAdaptor(cmp)),
    success: true,
    message: 'ok',
  };

  return data;
}

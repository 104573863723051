"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseCampaignResponseDtoStatus = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.BaseCampaignResponseDtoStatus = {
    draft: 'draft',
    active: 'active',
    in_active: 'in_active',
    schedule: 'schedule',
    expired: 'expired',
    need_upgrade: 'need_upgrade',
    processing: 'processing',
    in_progress: 'in_progress',
    queued: 'queued',
    action_required: 'action_required',
    order_discount_conflict: 'order_discount_conflict',
};

import {
  RESET_ERRORS,
  SET_ERRORS,
  REMOVE_ERRORS,
  REMOVE_ERROR_WITH_VALUE_IN_ARRAY,
} from '../constants';

const initialState = {
  errors: [],
};

export const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        errors: action?.payload,
      };
    case REMOVE_ERRORS:
      const newErrors = state.errors.filter((e) => !action?.payload.includes(e.field));
      return {
        ...state,
        errors: newErrors,
      };
    case REMOVE_ERROR_WITH_VALUE_IN_ARRAY:
      const otherErrors = state.errors.filter((e) => e.field !== action?.payload.field);
      const thatError = state.errors.find((e) => e.field === action?.payload.field);
      const editedThatError =
        thatError && thatError.value.length > 1
          ? [
              {
                ...thatError,
                value: thatError.value.filter((s) => s !== action?.payload.value.toString()),
              },
            ]
          : [];
      return {
        ...state,
        errors: [...otherErrors, ...editedThatError],
      };
    case RESET_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};

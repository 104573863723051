"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignControllerProgressSse = exports.campaignControllerStatusSse = exports.getCampaignProducts = exports.cancelCampaign = exports.activateCampaign = exports.deactivateCampaign = exports.updateAndActivateCampaign = exports.deleteCampaign = exports.getCampaign = exports.updateCampaign = exports.getActiveCampaignRevenueReport = exports.campaignControllerTest = exports.getEstimateProducts = exports.estimateRequest = exports.createAndActivateCampaign = exports.getCampaigns = exports.createCampaign = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary Create campaign for a shop
*/
const createCampaign = (campaignDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: campaignDto
    });
};
exports.createCampaign = createCampaign;
/**
* @summary Get campaigns of a shop
*/
const getCampaigns = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns`, method: 'get',
        params
    });
};
exports.getCampaigns = getCampaigns;
/**
* @summary Create and Activate campaign for a shop
*/
const createAndActivateCampaign = (campaignDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/createAndActivate`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: campaignDto
    });
};
exports.createAndActivateCampaign = createAndActivateCampaign;
/**
* @summary Product estimation request
*/
const estimateRequest = (campaignDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/estimate`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: campaignDto
    });
};
exports.estimateRequest = estimateRequest;
/**
* @summary Get Product estimation
*/
const getEstimateProducts = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/estimate`, method: 'get',
        params
    });
};
exports.getEstimateProducts = getEstimateProducts;
const campaignControllerTest = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/autoUpdate`, method: 'get'
    });
};
exports.campaignControllerTest = campaignControllerTest;
/**
* @summary Get active campaigns revenue
*/
const getActiveCampaignRevenueReport = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/getActiveCampaignsRevenueReport`, method: 'get'
    });
};
exports.getActiveCampaignRevenueReport = getActiveCampaignRevenueReport;
/**
* @summary Update campaign for a shop
*/
const updateCampaign = (id, campaignDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}`, method: 'put',
        headers: { 'Content-Type': 'application/json', },
        data: campaignDto
    });
};
exports.updateCampaign = updateCampaign;
/**
* @summary Get campaign for a shop
*/
const getCampaign = (id) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}`, method: 'get'
    });
};
exports.getCampaign = getCampaign;
/**
* @summary Delete campaign for a shop
*/
const deleteCampaign = (id) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}`, method: 'delete'
    });
};
exports.deleteCampaign = deleteCampaign;
/**
* @summary Update and activate campaign for a shop
*/
const updateAndActivateCampaign = (id, campaignDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}/updateAndActivate`, method: 'put',
        headers: { 'Content-Type': 'application/json', },
        data: campaignDto
    });
};
exports.updateAndActivateCampaign = updateAndActivateCampaign;
/**
* @summary Deactivate campaign for a shop
*/
const deactivateCampaign = (id) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}/deactivate`, method: 'get'
    });
};
exports.deactivateCampaign = deactivateCampaign;
/**
* @summary activate campaign for a shop
*/
const activateCampaign = (id) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}/activate`, method: 'get'
    });
};
exports.activateCampaign = activateCampaign;
/**
* @summary Cancel campaign for a shop by id
*/
const cancelCampaign = (id) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}/cancel`, method: 'get'
    });
};
exports.cancelCampaign = cancelCampaign;
/**
* @summary Get campaigns of a shop
*/
const getCampaignProducts = (id, params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/${id}/products`, method: 'get',
        params
    });
};
exports.getCampaignProducts = getCampaignProducts;
const campaignControllerStatusSse = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/sse/status`, method: 'get'
    });
};
exports.campaignControllerStatusSse = campaignControllerStatusSse;
const campaignControllerProgressSse = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/campaigns/sse/progress`, method: 'get',
        params
    });
};
exports.campaignControllerProgressSse = campaignControllerProgressSse;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import App from './App';
import './locales/i18n';
import reportWebVitals from './reportWebVitals';
import { rootReducer } from './states';

const composeEnhancers = composeWithDevTools({});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);

// function sendToGoogleAnalytics({ name, delta, value, id, entries }) {
//   window.gtag('event', name, {
//     // Built-in params:
//     value: delta, // Use `delta` so the value can be summed.
//     // Custom params:
//     metric_id: id, // Needed to aggregate events.
//     metric_value: value, // Value for querying in BQ
//     metric_delta: delta, // Delta for querying in BQ
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((d) => {
  // sendToGoogleAnalytics(d);
  console.log(d);
});

import { Modal } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'config/constants';
import { t } from 'i18next';
import Plans from '@/pricing/plans';
import { gaEventTrack } from 'utils/gaTrack';
import { useUpgradeModal } from 'hooks/useUpgradeModal';
import { upgardeModalClose } from 'states/actions/plans';
import { useDispatch } from 'react-redux';

const UpgradeModal = () => {
  const { show, gaEventTrackingInfo, permissionKey } = useUpgradeModal();
  const history = useHistory();
  const dispatch = useDispatch();

  const onClose = () => {
    upgardeModalClose(dispatch)();
  };

  // show={upgradeModalShow}
  //         onClose={handleCloseUpgradeModal}
  //         permissionKey={permissionKey}
  //         gaEventTrackingInfo={{ category: 'Campaigns edit', label: 'Upgrade Modal' }}

  const handleClose = (event) => {
    onClose();
    if (event) {
      event?.preventDefault();
      event?.stopPropagation();
      event?.nativeEvent.stopImmediatePropagation();
    }
  };

  const onSeeAllPlansClick = () => {
    upgardeModalClose(dispatch)();
    gaEventTrack('Campaigns edit', 'Click', 'see all plans (upgrade modal)');
    history.push(PATHS.PRICING);
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        title={t('plans_modal_title')}
        size="large"
        secondaryActions={[
          {
            content: t('btn_see_all_plans'),
            onAction: () => onSeeAllPlansClick(),
          },
        ]}
      >
        <Modal.Section>
          <Plans
            isUpgrating
            permissionKey={permissionKey}
            discountInfo={{}}
            selectedPeriod={0}
            afterUpgrade={handleClose}
            gaEventTrackingInfo={gaEventTrackingInfo}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};
export default UpgradeModal;

import { SET_SETTING } from '../constants';

const initialState = {
  contactEmail: '',
  newCampaignEmail: false,
  scheduledCampaignStartEmail: false,
  liveCampaignExpireEmail: false,
  liveCampaignDisableEmail: false,
  enableCampaignEmail: false,
  orderInDiscountedProductEmail: false,
  preventShopifyDiscountCode: false,
  loading: true,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTING:
      return {
        ...state,
        ...action?.payload,
        loading: false,
      };
    default:
      return state;
  }
};

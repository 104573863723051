"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlanUsage = exports.getPlans = exports.pricingControllerVerify = exports.upgradePlan = void 0;
const fetchInstance_1 = require("../../../utils/fetchInstance");
/**
* @summary Upgrade Plan
*/
const upgradePlan = (upgradePlanDto) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/pricing/upgradePlan`, method: 'post',
        headers: { 'Content-Type': 'application/json', },
        data: upgradePlanDto
    });
};
exports.upgradePlan = upgradePlan;
const pricingControllerVerify = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/pricing/verify`, method: 'get'
    });
};
exports.pricingControllerVerify = pricingControllerVerify;
/**
* @summary Get list of plans
*/
const getPlans = (params) => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/pricing/plans`, method: 'get',
        params
    });
};
exports.getPlans = getPlans;
/**
* @summary Get current plan and usage
*/
const getPlanUsage = () => {
    return (0, fetchInstance_1.fetchInstance)({ url: `/api/pricing/planUsage`, method: 'get'
    });
};
exports.getPlanUsage = getPlanUsage;

import React, { useEffect } from 'react';
import { Grid } from '@shopify/polaris';

import PlanCard from '@/pricing/PlanCard';
import { usePlans } from 'hooks/usePlans';
import { useDispatch } from 'react-redux';
import { getPlansAction } from 'states/actions/plans';
import { useCurrentPlan } from 'hooks/useCurrentPlan';
import './plans.scss';

const Plans = ({
  selectedPeriod,
  afterUpgrade,
  isUpgrating,
  gaEventTrackingInfo,
  permissionKey,
}) => {
  const plans = usePlans(isUpgrating, permissionKey);
  const currentPlan = useCurrentPlan();
  const dispatch = useDispatch();
  useEffect(() => {
    if (plans?.length <= 0) {
      getPlansAction(dispatch)();
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="plansSection">
      {plans?.length > 0 && currentPlan && (
        <Grid>
          {plans.map((plan, index) =>
            (plan?.id === '196ecca3-3685-48f9-aa4a-c73a41ef7e2b' && plan?.id !== currentPlan?.id) ||
            (isUpgrating && plan.isFree) ||
            (isUpgrating && plan.monthlyPrice < currentPlan.monthlyPrice) ? (
              <span style={{ display: 'none' }}></span>
            ) : (
              <Grid.Cell
                key={plan?.id}
                columnSpan={{
                  xs: 6,
                  sm: 3,
                  md: 3,
                  lg: isUpgrating ? 4 : 3,
                  xl: isUpgrating ? 4 : 3,
                }}
              >
                <PlanCard
                  plan={plan}
                  period={selectedPeriod}
                  index={index}
                  afterUpgrade={afterUpgrade}
                  isUpgrating={isUpgrating}
                  gaEventTrackingInfo={gaEventTrackingInfo}
                />
              </Grid.Cell>
            )
          )}

          {/* when plan is deactive and still working on shop */}
          {!plans?.find((p) => p.id === currentPlan?.id) && (
            <Grid.Cell
              key={currentPlan?.id}
              columnSpan={{ xs: 6, sm: 3, md: 3, lg: isUpgrating ? 4 : 3, xl: isUpgrating ? 4 : 3 }}
            >
              <PlanCard plan={currentPlan} period={selectedPeriod} afterUpgrade={afterUpgrade} />
            </Grid.Cell>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Plans;

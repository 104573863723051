import {
  FETCH_PLANS,
  SET_DISCOUNT_LOADING,
  SET_PALNS_AND_DISCOUNT,
  SET_DISCOUNT_ERROR,
  CLEAR_DISCOUNT_ERROR,
  SET_PALNS_WITHOUT_DISCOUNT,
  UPGRADE_MODAL_OPEN,
  UPGRADE_MODAL_CLOSE,
} from '../constants';

const initialState = {
  plans: [],
  discount: undefined,
  discountLoading: undefined,
  discountError: undefined,
  upgradeModal: {
    show: false,
    gaEventTrackingInfo: undefined,
    permissionKey: undefined,
  },
};

export const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANS:
      return {
        ...state,
        plans: action?.payload,
      };
    case SET_DISCOUNT_LOADING:
      return {
        ...state,
        discountError: undefined,
        discountLoading: action?.payload,
      };
    case SET_PALNS_WITHOUT_DISCOUNT:
      return {
        ...state,
        discount: undefined,
        discountLoading: false,
        discountError: undefined,
        plans: action?.payload,
      };
    case SET_PALNS_AND_DISCOUNT:
      return {
        ...state,
        ...action?.payload,
        discountLoading: false,
        discountError: undefined,
      };
    case SET_DISCOUNT_ERROR:
      return {
        ...state,
        discountLoading: false,
        discountError: action?.payload,
      };
    case CLEAR_DISCOUNT_ERROR:
      return {
        ...state,
        discountError: undefined,
      };
    case UPGRADE_MODAL_OPEN:
      return {
        ...state,
        upgradeModal: {
          ...action?.payload,
          show: true,
        },
      };
    case UPGRADE_MODAL_CLOSE:
      return {
        ...state,
        upgradeModal: {
          show: false,
          gaEventTrackingInfo: undefined,
          permissionKey: undefined,
        },
      };
    default:
      return state;
  }
};

"use strict";
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Shopify Api Doc
 * OpenAPI spec version: v1.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEstimateProducts200Status = void 0;
// eslint-disable-next-line @typescript-eslint/no-redeclare
exports.GetEstimateProducts200Status = {
    'processing,_\'done': 'processing, \'done',
};

import { useSelector } from 'react-redux';

export const usePlanDiscount = (period) => {
  const { discount } = useSelector((state) => state?.plans);
  return discount?.type === period || discount?.type === 2 ? discount : undefined;
};

export const usePlanDiscountWithotPeriod = (period) => {
  const { discount } = useSelector((state) => state?.plans);
  return discount;
};
